






































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import {Get, Sync} from 'vuex-pathify';
import { IEaasiTab } from 'eaasi-nav';
import { IEaasiResource } from '@/types/Resource';
import MyBookmarksScreen from '@/components/resources/my-resources/MyBookmarksScreen.vue';
import ImportedResourcesScreen from '@/components/resources/my-resources/ImportedResourcesScreen.vue';
import ResourceSlideMenu from '@/components/resources/ResourceSlideMenu.vue';
import SlideMenuControlButtons from '@/components/resources/SlideMenuControlButtons.vue';

@Component({
	name: 'MyResourcesScreen',
	components: {
		/*MyBookmarksScreen,*/
		ResourceSlideMenu,
		ImportedResourcesScreen,
		SlideMenuControlButtons
	}
})
export default class MyResourcesScreen extends Vue {

	/* Props
	============================================*/
	@Prop({ type: String, required: false, default: 'Imported Resources' })
	defaultTab: string;

	/* Computed
	============================================*/

	@Sync('resource/selectedResources')
    selectedResources: IEaasiResource[];

	@Get('resource/slideMenuTabs')
	readonly actionMenuTabs: IEaasiTab[];

	get hasActiveResources() {
		return this.selectedResources.length > 0;
	}

	get isActionMenuOpen(): boolean {
		return this.actionMenuActiveTab != null && this.hasActiveResources;
	}

	get actionMenuStyles(): string {
		let styles = '';
		if (!this.isActionMenuOpen) return styles;
		let maxWidth = document.body.clientWidth - (430 + 90); // screen width - (action menu width + side menu bar width)
		styles += `overflow-y: scroll; max-width: ${maxWidth}px;`;
		return styles;
	}

	get innerStyles(): string {
		let styles = '';
		if (!this.isActionMenuOpen) return styles;
		let width = '95vw'; // screen width
		styles += `width: ${width};`;
		return styles;
	}

	/* Data
	============================================*/
	activeTab: string = 'Imported Resources';
	tabs: IEaasiTab[] = [
		/*{
			label: 'My Bookmarks',
			disabled: false
		},*/
		{
			label: 'Imported Resources',
			disabled: false
		},
		// hidden per https://gitlab.com/eaasi/eaasi-client-dev/-/issues/608
		// {
		// 	label: 'My Contributions',
		// 	disabled: true
		// }
	];

	actionMenuActiveTab: IEaasiTab = {
		label: 'Imported Resources',
		disabled: false
	};

	/* Methods
	============================================*/
	refresh() {
		const currentTab = this.activeTab;
		this.activeTab = '';
		this.$nextTick(() => this.activeTab = currentTab);
	}

	openActionMenu(tab: IEaasiTab = this.actionMenuTabs[0]) {
		this.actionMenuActiveTab = tab;
	}

	closeActionMenu() {
		this.actionMenuActiveTab = null;
	}

	/* Lifecycle Hooks
	============================================*/
	created() {
		this.activeTab = this.defaultTab;
	}

	@Watch('hasActiveResources')
	onSelectResources(curVal, prevVal) {
		if (curVal && !prevVal) {
			this.openActionMenu();
		}
	}
}

