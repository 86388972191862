







import { Component, Vue } from 'vue-property-decorator';
import DashboardHeader from '@/components/dashboard/DashboardHeader.vue';
import DashboardBlogFeed from '@/components/dashboard/DashboardBlogFeed.vue';
import EaasiIcon from '@/components/global/icons/EaasiIcon.vue';

@Component({
	name: 'Dashboard',
	components: {
		DashboardHeader,
		DashboardBlogFeed,
		EaasiIcon,
	}
})
export default class Dashboard extends Vue {
}
