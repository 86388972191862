




































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Get, Sync } from 'vuex-pathify';
import { IResourceSearchResponse, IResourceSearchFacet, IResourceSearchQuery } from '@/types/Search';
import { IBookmark } from '@/types/Bookmark';
import { IEaasiResource } from '@/types/Resource';
import User from '@/models/admin/User';
import ResourceList from '@/components/resources/ResourceList.vue';
import ResourceSlideMenu from '@/components/resources/ResourceSlideMenu.vue';
import AppliedSearchFacets from '@/components/resources/search/AppliedSearchFacets.vue';
import NoSearchResult from '@/components/resources/search/NoSearchResult.vue';
import ResourceFacets from '@/components/resources/search/ResourceFacets.vue';
import ResourceSortSection from '../search/ResourceSortSection.vue';
import { IEaasiTab } from 'eaasi-nav';
import SlideMenuControlButtons from '@/components/resources/SlideMenuControlButtons.vue';
import SearchQueryService, { QuerySource } from '@/services/SearchQueryService';

@Component({
	name: 'ExploreResourcesScreen',
	components: {
		SlideMenuControlButtons,
		ResourceSortSection,
		AppliedSearchFacets,
		ResourceFacets,
		ResourceList,
		ResourceSlideMenu,
		NoSearchResult
	}
})
export default class ExploreResourcesScreen extends Vue {

	/* Computed
    ============================================*/

	@Get('resource/result')
	bentoResult: IResourceSearchResponse;

	@Sync('resource/query')
	query: IResourceSearchQuery;

    @Sync('resource/selectedResources')
    selectedResources: IEaasiResource[];

	@Sync('resource/query@selectedFacets')
	selectedFacets: IResourceSearchFacet[];

	@Get('resource/facetsOfSingleTypeSelected')
	facetsOfSingleTypeSelected: Boolean;

	@Get('resource/facetsOfResourceTypesSelected')
	facetsOfResourceTypesSelected: String[];

	@Get('loggedInUser')
	user: User;

	/*@Get('bookmark/bookmarks')
	bookmarks: IBookmark[];*/

	@Get('resource/onlySelectedFacets')
	onlySelectedFacets: IResourceSearchFacet[];

	@Get('resource/slideMenuTabs')
	readonly tabs: IEaasiTab[];

	get totalResults() {
		if (!this.onlySelectedFacets)  {
			return;
		}
		const totalResultsArr = this.onlySelectedFacets.flatMap(f => f.values.map(v => v.total));
		return Math.min.apply(null, totalResultsArr);
	}

	get noResult() {
		const { content, software, environments, images } = this.bentoResult;
		return content.result.length === 0
			&& software.result.length === 0
			&& environments.result.length === 0
			&& images.result.length === 0;
	}

	get hasActiveResources() {
		return this.selectedResources.length > 0;
	}

	get hasSelectedFacets() {
		return this.onlySelectedFacets.length > 0;
	}

	get isActionMenuOpen(): boolean {
		return this.activeTab != null && this.hasActiveResources;
	}

	get actionMenuStyles(): string {
		let styles = '';
		if (!this.isActionMenuOpen) return styles;
		let maxWidth = document.body.clientWidth - (430 + 90); // screen width - (action menu width + side menu bar width)
		styles += `overflow-y: scroll; max-width: ${maxWidth}px;`;
		return styles;
	}

	get innerStyles(): string {
		let styles = '';
		if (!this.isActionMenuOpen) return styles;
		let width = '95vw'; // screen width
		styles += `width: ${width};`;
		return styles;
	}

	/* Data
	============================================*/
	private queryService = new SearchQueryService(QuerySource.ExploreResources);

	activeTab: IEaasiTab = null;

    /* Methods
	============================================*/

	async paginate(page) {
		this.query.page = page;
		await this.search();
	}

	update() {
		this.$router.go(0);
	}

    async search() {
		// wait for facets update it's selected property on this tick, call search on next tick
		this.$nextTick(async () => {
			this.query = {
				...this.query,
				userId: this.user.id,
				//onlyBookmarks: false,
				onlyImportedResources: false,
				archives: []
			};

			await this.$store.dispatch('resource/searchResources');

			/*const bookmarks = this.bentoResult?.bookmarks;
			if (!bookmarks || !bookmarks.length) {
				this.bentoResult.bookmarks = this.bookmarks;
			}*/
		});
	}

    async getAll(types) {
		this.query.keyword = null;
		this.$store.commit('resource/SET_SELECTED_FACET_RESOURCE_TYPE', types);
		await this.search();
	}

	onResourcePublished() {
    	this.$router.go(0);
	}

	async init() {
		const { keyword } = this.$route.params;
		const { retrieveQuery } = this.$route.query;
		if (retrieveQuery) {
			const query: IResourceSearchQuery = this.queryService.retrieveQuery();
			if (query) {
				this.query = query;
			}
		}
		if (keyword) {
			this.query.keyword = keyword;
		}

		// prefetch bookmarks once...
		//await this.$store.dispatch('bookmark/getBookmarks', this.user.id);

		await this.search();
	}

	openActionMenu(tab: IEaasiTab = this.tabs[0]) {
		this.activeTab = tab;
	}

	closeActionMenu() {
		this.activeTab = null;
	}

    /* Lifecycle Hooks
    ============================================*/

    beforeMount() {
		this.init();
    }

	beforeDestroy() {
		this.queryService.persistQuery(this.query);
		this.selectedResources = [];
		this.$store.dispatch('resource/clearSearchQuery');
		this.$store.commit('resource/SET_RESULT', null);
	}

	@Watch('hasActiveResources')
	onSelectResources(curVal, prevVal) {
		if (curVal && !prevVal) {
			this.openActionMenu();
		}
	}

	@Watch('selectedResources')
	onSelectedResourceChange(curVal) {
    	if (curVal.length > 1) {
    		this.activeTab = this.tabs[0];
		}
	}
}

